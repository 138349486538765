.footer-container {
  background: linear-gradient(98.3deg, #5c7ae8 0%, #75d6a1 100%);
  padding: 6rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 165px;
  font-family: 'Montserrat', sans-serif;
}
#icons {
  padding-right: 15px;
}
.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription-heading {
  margin-left: -50rem;
  margin-top: 135px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-links {
  width: 100%;
  margin-top: -150px;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
  margin-right: -25px;
}
#child2 {
  width: 200px;
}
#child3 {
  width: 190px;
}
.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 56px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  font-size: 19px;
  line-height: 23px;
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  width: 315px;
  @media screen and (max-width: 820px) {
    width: 100%;
  }
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
  margin-top: -75px;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  padding-bottom: 90px;
  margin-top: 80px;
  padding-left: 450px;
  width: 100%;
  height: 100%;
}
.footer-link-items .grey {
  color: #e6e6e6;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}
.address {
  width: 387px;
}

@media screen and (max-width: 600px) {
  .address {
    width: auto;
  }

  .footer-container {
    padding: 45px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
  }

  .footer-subscription {
    margin: 0;
    padding: 0;
    img {
      width: 155px;
      margin: 0;
      height: 43px;
    }
  }

  .footer-subscription-heading {
    margin-left: 0;
    font-size: 24px;
  }

  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }

  .footer-links {
    width: 100%;
    max-width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: -215px;
  }

  .footer-link-wrapper {
    display: flex;
    margin-right: 0;
    &:nth-child(1) {
      flex-direction: row;
      justify-content: space-between;
      margin-right: 18px;
    }
  }
  #child2 {
    width: 170px;
  }
  #child3 {
    width: 190px;
  }
  .footer-link-items {
    margin: 0;
    width: auto;
    margin-bottom: 30px;
  }

  .footer-link-items h2 {
    margin-bottom: 16px;
  }

  .footer-link-items > h2 {
    color: #fff;
  }

  .footer-link-items a {
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }

  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }

  .footer-email-form h2 {
    margin-bottom: 2rem;
  }

  .footer-input::placeholder {
    color: #b1b1b1;
  }

  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }

  .social-media {
    max-width: 1000px;
    width: 100%;
  }

  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 0;
  }

  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    margin-top: 73px;
  }

  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .website-rights {
    color: #fff;
    padding-left: 0;
    width: 100%;
    height: 100%;
    margin-top: 110px;
  }
  .footer-subscription img {
    margin-left: -13rem;
    margin-top: -190px;
    font-size: 24px;
  }
}
@media screen and (max-width: 450px) {
  .footer-link-wrapper {
    &:nth-child(1) {
      margin-right: 0;
    }
  }
  .footer-container {
    a {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }
}
