.tablemain {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  .tableh {
    color: #1942df;
    margin-bottom: 55px;
    width: 926px;
    font-family: 'Montserrat';

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
  }

  .tableimg {
    width: 125px;
    height: 35px;
    margin-bottom: 3px;
  }

  .fs {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
  }

  .tables {
    width: 100%;
    max-width: 700px;
    background: #5c7ae7;
    border-radius: 15px;
    position: relative;
    padding: 30px;

    .img {
      position: absolute;
      right: -48px;
      top: -44px;

      &.last {
        left: -75px;
        top: auto;
        bottom: -49px;
      }

      &.keyfirst {
        left: 593px;
        top: auto;
        bottom: -65px;
      }

      &.keylast {
        left: -81px;
        top: auto;
        bottom: 378px;
      }
    }
  }

  td,
  th {
    border: 1px solid #dddddd !important;
    text-align: left;
    padding: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 44px;
    border-width: 80%;
    color: #ffffff;
  }

  th:first-child {
    border-top: none !important;
    border-width: 80%;
    border-left: none !important;
  }

  th:nth-child(2) {
    border-top: none !important;
    //   border-left: none;
  }

  td,
  th:last-child {
    border-top: none !important;
    border-right: none !important;
  }

  td:first-child {
    //   border-top: none;
    border-left: none !important;
  }

  .border-none {
    border-bottom: none !important;
  }

  .tables-section {
    display: flex;
    margin-bottom: 200px;
  }

  .tables-section-titles {
    width: 60%;
    margin-right: 70px;
    margin-top: 100px;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 19px;
      line-height: 137.9%;
      color: #231f20;
      margin-bottom: 60px;
    }

    a {
      padding: 11px 33px;
      background: #ff5a3a;
      box-shadow: -3px 4px 8px rgba(255, 90, 58, 0.4);
      border-radius: 50px;
      border: none;
      outline: none;
      color: white;
    }
    a:hover {
      padding: 11px 33px;
      background: #ff5a3a;
      box-shadow: -3px 4px 8px rgba(255, 90, 58, 0.4);
      border-radius: 50px;
      border: none;
      outline: none;
      color: white;
    }
  }

  #border-none {
    border-bottom: none !important;
  }
}

@media screen and (max-width: 450px) {
  .tablemain {
    table {
      margin-left: -17px;
    }
    .tables {
      .img {
        right: -21px;
        top: -79px;
      }
    }

    // img.img.first {
    //   display: none;
    // }

    .img.img.last {
      left: -36px;
      top: auto;
      bottom: -83px;
    }

    .img {
      &.keyfirst {
        display: none;
      }

      &.keylast {
        display: none;
      }
    }

    .tableh {
      width: 245px;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 157%;
      color: #1942df;
    }

    .tables-section {
      flex-direction: column;
      width: 100%;

      button {
        // display: flex;
        margin: 0 auto;
        margin-bottom: 20px;
      }

      .tables-section-titles {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
        margin-right: 0;
        width: 353px;
      }

      td,
      th {
        border: 1px solid #dddddd !important;
        text-align: left;
        padding: 4px;
        font-style: normal;
        font-weight: 700;
        font-size: 9px;
        line-height: 23px;
        border-width: 80%;
        color: #ffffff;
        padding-left: 12px;
      }

      th:first-child {
        border-top: none !important;
        border-width: 80%;
        border-left: none !important;
      }

      th:nth-child(2) {
        border-top: none !important;
        //   border-left: none;
      }

      td,
      th:last-child {
        border-top: none !important;
        border-right: none !important;
      }

      .bankth {
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */

        letter-spacing: 0.19em;
        padding-bottom: 10px;
      }

      td:first-child {
        //   border-top: none;
        border-left: none !important;
      }

      .border-none {
        border-bottom: none !important;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
      }

      #border-none {
        border-bottom: none !important;
      }

      img,
      svg {
        margin-bottom: 8px;
        margin-left: -5px;
      }

      .fs {
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: 377px) {
  .tablemain {
    .tables {
      .img {
        position: absolute;
        right: -20px;
        top: -44px;
      }
    }
  }
}
