.company-banner {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }

  .banner-title {
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 73px;
      color: #1942df;
      margin-bottom: 60px;
      @media screen and (max-width: 450px) {
        font-size: 30px;
        line-height: 137%;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 28px;
      color: #000000;
      margin-bottom: 30px;
      @media screen and (max-width: 450px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .banner-img {
    img {
      width: 500px;
      height: 500px;
      @media screen and (max-width: 450px) {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
.pdf-section {
  padding-bottom: 80px;
  position: relative;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #1942df;
    margin-bottom: 62px;
  }
  .before {
    position: absolute;
    left: 0;
    top: 70px;
    width: 137px;
    height: 137px;
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
  .left {
    position: absolute;
    left: 30%;
    bottom: 0;
    width: 137px;
    height: 137px;
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
  .center {
    position: absolute;
    right: 35%;
    top: 0;
    width: 137px;
    height: 137px;
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
  .right {
    width: 137px;
    height: 137px;
    top: 130px;
    position: absolute;
    right: 0;
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
  .pdf-item {
    @media screen and (max-width: 450px) {
      margin-left: 0;
      a {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #231f20;
      }
    }
    margin-left: 50px;
    margin-bottom: 20px;
    img {
      margin-right: 14px;
    }
  }
}
.mission {
  padding-bottom: 100px;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    // margin-bottom: 20px;
    color: #1942df;
    @media screen and (max-width: 450px) {
      margin-bottom: 20px;
      font-size: 30px;
      line-height: 137%;
    }
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;

    margin-bottom: 20px;
    color: #000000;
    @media screen and (max-width: 450px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .mission-section {
    display: flex;
    align-items: center;
    @media screen and (max-width: 450px) {
      flex-direction: column;
      img {
        width: 100%;
      }
    }
  }
}
.pdf-item a {
  text-decoration-line: underline;
  color: #231f20;
}
