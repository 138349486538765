.accordion-collapse {
  background: #f8f9ff;
  box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);
  border-radius: 15px;
}
.yuridik-accordion {
  margin-top: -60px;
}
.sale {
  img {
    width: 157px;
    height: 164px;
    @media screen and (max-width: 450px) {
      width: 85px;
      object-fit: contain;
    }
  }
}
.yuridik {
  margin-top: 70px;
  .accordion-body {
    @media screen and (max-width: 450px) {
      // overflow: scroll;
    }
    li {
      margin-bottom: 20px;
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 19px;
      line-height: 23px;
      margin-top: 12px;
      // color: #231f20;
    }
  }
  .accordion-button {
    &:after {
      content: '';
      right: 30px;
      position: absolute;
      top: 30px;
      background-image: url('./arrow.svg');
      background-size: contain;
    }
  }
  .yuridik-banner {
    display: flex;
    align-items: center;
    padding: 0 0 50px 0;
    margin-top: 135px;

    @media screen and (max-width: 450px) {
      flex-direction: column;
      margin-top: 5px;
      padding: 0;
      height: 750px;
    }

    .banner-title {
      @media screen and (max-width: 450px) {
        width: 100%;

        p {
          width: 100%;
          font-size: 16px;
          line-height: 137%;
        }

        h2 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 37px;
          width: 255px;
          margin-top: 30px;
        }
      }
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 19px;
      line-height: 27px;
      width: 50%;
      color: #231f20;
      margin-bottom: 20px;
    }

    h2 {
      font-style: normal;
      margin-bottom: 25px;
      font-weight: 700;
      font-size: 60px;
      width: 100%;
      line-height: 73px;
      color: #1942df;
    }

    .banner-img {
      position: absolute;
      right: 0;

      @media screen and (max-width: 450px) {
        position: static;

        img {
          width: 100%;
          margin-left: -25px;
        }
      }
    }
  }
  .accordion-body h1 {
    color: #1942df;
    margin-bottom: 20px;
    font-size: 27px;
  }
  .accordion-body h5 {
    color: #231f20;
    margin-bottom: 20px;
    font-size: 19px;
  }
  .accordion-body ul {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 155.9%;
  }

  .accordion-body h4 {
    font-size: 19px;
    line-height: 155.9%;
    color: #1942df;
    margin-bottom: 15px;
  }

  .greyp {
    color: #595959;
    font-size: 17px;
    line-height: 155.9%;
  }

  .grid-accordion {
    @media screen and (max-width: 450px) {
      grid-template-columns: 1fr;
    }

    gap: 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .accordion-item {
      @media screen and (max-width: 450px) {
        width: 100%;
        margin-bottom: 160px;
      }
      width: 48%;
      &:nth-child(3n) {
        width: 100%;
      }
    }
  }

  .accordion-body h1 {
    color: #1942df;
    margin-bottom: 20px;
    font-size: 18px;
  }

  .accordion-body h5 {
    color: #231f20;
    margin-bottom: 20px;
    font-size: 17px;
  }

  .accordion-body ul {
    font-style: normal;
    padding: 0 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 155.9%;
    &::-webkit-scrollbar {
      display: block !important;
    }
    // overflow: scroll;
  }

  .accordion {
    .accordion-item {
      border: none;

      &.last {
        width: 100%;
      }

      .accordion-header {
        .accordion-button {
          background: #f8f9ff !important;
          padding-right: 50px;

          &.collapsed {
            h2 {
              font-style: normal;
              text-align: left;
              font-weight: 600;
              font-size: 27px;
              margin-bottom: 30px;
              line-height: 33px;
              color: #1942df;
              content: ' ';
            }

            p {
              font-style: normal;
              font-weight: 500;
              text-align: left;
              font-size: 19px;
              line-height: 23px;
              color: #231f20;
            }
          }

          display: block;
          height: 180px;

          h2 {
            font-style: normal;
            text-align: left;
            font-weight: 600;
            font-size: 27px;
            margin-bottom: 30px;
            line-height: 33px;
            color: #1942df;
          }

          p {
            font-style: normal;
            font-weight: 500;
            text-align: left;
            font-size: 19px;
            line-height: 23px;
            color: #000;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .yuridik {
    margin-top: 0;
    .accordion {
      .accordion-item {
        border: none;
        background: #f8f9ff;
        box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);
        border-radius: 15px;
        &.last {
          width: 100%;
        }

        .accordion-header {
          .accordion-button {
            background: #f8f9ff !important;
            border: none;

            &.collapsed {
              h2 {
                font-size: 17px;
                line-height: 22px;
              }

              p {
                font-size: 17px;
                line-height: 21px;
              }
            }

            display: block;
            height: 100%;
            margin-top: -140px;

            h2 {
              font-size: 17px;
              line-height: 21px;
            }

            p {
              font-size: 17px;
              line-height: 21px;
              color: #000;
            }
          }
        }
      }
    }
  }
}

// .accordion-button:not(.collapsed)::after {
//   background-image: var(--bs-accordion-btn-active-icon);
//   transform: var(--bs-accordion-btn-icon-transform);
// }
// .accordion-button::after {
//   flex-shrink: 0;
//   width: var(--bs-accordion-btn-icon-width);
//   height: var(--bs-accordion-btn-icon-width);
//   margin-left: auto;
//   content: "";
//   background-image: var(--bs-accordion-btn-icon);
//   background-repeat: no-repeat;
//   background-size: var(--bs-accordion-btn-icon-width);
//   transition: var(--bs-accordion-btn-icon-transition);
// }
// .accordion-button:not(.collapsed) {
//   color: #fff !important;
//   background: linear-gradient(90deg, #5c7ae8 0%, #76d7a1 100%) !important;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 13px;
//   line-height: 18px;
//   text-align: center;
// }

// .accordion-button::after {
//   flex-shrink: 0;
//
//   background-repeat: no-repeat;
// }

#text {
  width: 100px !important;
}
.yuridikbutton {
  display: flex;
  align-items: center;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  background: #ff5a3a;
  box-shadow: -3px 4px 8px rgba(255, 90, 58, 0.4);
  // border-radius: 0 0 50px 50px;
  border-radius: 0px 0 15px 15px;
  padding: 6px 11px;
  width: 270px;
  margin-bottom: 50px;
  img {
    margin: 10px;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 180px;
    margin-left: 40px;
  }
}
.yuridikbutton-desktop:hover {
  color: #fff;
}
.yuridikbutton:hover {
  color: #fff;
}
.header2 {
  display: none;
  z-index: 10;
  position: sticky;
  top: 0px;
  height: 3rem;
  right: 30%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  @media screen and (max-width: 450px) {
    display: block;
  }
  &.show {
    top: 85px;
  }
}

.header.down {
  top: -6rem;
}
.yuridikbutton-desktop {
  position: fixed;
  top: 45%;
  background: #ff5a3a;
  box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.21);
  border-radius: 0px 0 15px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  border: none;
  outline: none;
  padding: 38px 28px;
  display: flex;
  align-items: center;
  color: #ffffff;
  transform: rotate(-90deg);
  width: 300px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -120px;
  z-index: 1000;
  img {
    margin-right: 10px;
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
}
.producttextyur {
  width: 700px;
  margin: 0 auto;

  padding-bottom: 30px;
}
@media screen and (max-width: 600px) {
  .producttextyur {
    width: 300px;
    margin-bottom: 40px;
    padding: 0;
  }
}
