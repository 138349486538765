* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat';
}

.physical {
  width: 100%;
  height: 100%;
}

.physicalbutton {
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  background: #ff5a3a;
  box-shadow: -3px 4px 8px rgba(255, 90, 58, 0.4);
  border-radius: 50px;
  padding: 15px 40px;
  width: 270px;
  margin-left: 20px;
  @media screen and (max-width: 450px) {
    margin: 0 auto;
  }
}
.physicalbutton:hover {
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  background: #ff5a3a;
  box-shadow: -3px 4px 8px rgba(255, 90, 58, 0.4);
  border-radius: 50px;
  padding: 15px 40px;
}
.physical-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
}

.physical-left {
  li {
    display: block;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 141px;

  @media screen and (max-width: 450px) {
    margin-bottom: 0;
  }
}

.physical-img {
  position: absolute;
  right: 0;
  height: 725px;
  margin-top: -95px;

  @media screen and (max-width: 450px) {
    position: static;
    width: 100%;
  }
}

.physical-left {
  li {
    display: flex;
  }
}

.physical-left h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  margin: 20px 0;
  width: 324px;

  color: #1942df;
}

ul {
  list-style: none;

  /* Remove default bullets */
  @media screen and (max-width: 450px) {
    padding: 0 25px;

    li {
      font-size: 1p7x;
    }
  }
}

ul li::before {
  content: '\2022';
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  content: '•';
  color: #1942df;
  font-weight: bold;
  display: inline-block;
  width: 15px;
  height: 47px;
  margin-left: -1rem;
  /* font-weight: 500; */
  font-size: 16px;
  line-height: 151.9%;
}

.box3 {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);
  border-radius: 15px;
  margin-bottom: 110px;
  padding: 45px;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 25px;

    @media screen and (max-width: 450px) {
      font-size: 18px;
      line-height: 22px;
    }

    color: #1942df;
  }
}

.box {
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 25px;

    @media screen and (max-width: 450px) {
      font-size: 18px;
      line-height: 22px;
      margin-right: 0;
    }

    color: #1942df;
  }
}

.physical-container2 {
  margin-bottom: 30px;
  flex-wrap: wrap;
  //   height: 100vh;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
}

.box-container {
  width: 85%;
  margin: 0 auto;

  margin-top: 52px;
}

.h3 {
  color: #1942df;
}

.box {
  //   transition: all ease 0.5s;
  margin-bottom: 20px;
  width: 48%;
  // height: 360px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);
  background: linear-gradient(110.42deg, #fff 0%, #fff 100%);

  @media screen and (max-width: 450px) {
    width: 100%;
    margin-bottom: 50px;
  }

  margin-right: 15px;

  &:nth-child(3n) {
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    margin-right: 0;
  }

  &:hover {
    color: white;
    // transition: all ease 0.5s;
    background: linear-gradient(110.42deg, #5d7ee5 0%, #73cea7 100%);
    box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);

    h3 {
      color: #fff;
    }
  }
}

.box3 {
  //   transition: all ease 0.5s;
  &:hover {
    color: white;
    // transition: all ease 0.5s;
    background: linear-gradient(110.42deg, #5d7ee5 0%, #73cea7 100%);
    box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);

    h3 {
      color: #fff;
    }
  }
}

.box3 {
  //   transition: all ease 0.5s;
  &:hover {
    color: white;
    // transition: all ease 0.5s;
  }
}

.box-container {
  //   transition: all ease 0.5s;
  &:hover {
    color: white;
    // transition: all ease 0.5s;
  }
}

.box-container h1 {
  margin-top: 35px;
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 33px;
  color: #1942df;
  margin-bottom: 20px;

  @media screen and (max-width: 450px) {
    font-size: 18px;
    line-height: 22px;
  }
}

.box-container p {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;

  @media screen and (max-width: 450px) {
    font-size: 17px;
    line-height: 21px;
  }
}

.box img {
  width: 157px;
  height: 164px;

  @media screen and (max-width: 450px) {
    width: 100px;
  }
}

.sale {
  // position: absolute;
  display: flex;
  align-items: center;
  // justify-content: center;
  text-align: center;

  img {
    @media screen and (max-width: 450px) {
      width: 85px;
      object-fit: contain;
    }
  }
}

.sale2 {
  // position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  img {
    @media screen and (max-width: 450px) {
      width: 100px;
    }
  }
}

.sale p {
  position: relative;
}

#ph1 {
  margin-top: 80px;

  @media screen and (max-width: 450px) {
    margin: 0;
  }
}

h1.physicalh {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;

  color: #1942df;
  width: 968px;
  margin-top: 30px;

  @media screen and (max-width: 450px) {
    width: 357px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
  }
}

#mainh {
  margin-top: 90px;
}

.physicalp {
  width: 531px;
  margin-left: 25px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;

  color: #231f20;

  @media screen and (max-width: 450px) {
    width: 324px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 137%;
  }
}

.physicali {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 151.9%;
  /* identical to box height, or 29px */

  color: #231f20;
}

.producttextph {
  width: 700px;
  margin: 0 auto;
}
@media screen and (max-width: 600px) {
  .producttextph {
    width: 300px;
    margin-top: -30px;
    margin-bottom: 40px;
  }
}
